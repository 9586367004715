<template>
  <div class="card wc-card">
    <button
      class="row no-gutters text-primary justify-content-between w-100"
      :aria-expanded="collapsed ? 'true' : 'false'"
      :aria-controls="'collapse_aside-' + id"
      data-toggle="collapse"
      :class="['card-header', 'wc-collapse-header', headerClass]"
      :style="{ 'background-color': headerBackground }"
      @click="toggle"
    >
      <slot name="headerTitle"></slot>
      <font-awesome-icon
        class="my-auto ml-auto"
        :icon="collapsibleIcon"
        :title="collapsibleIconTitle"
      />
    </button>
    <!-- START : Transition for collapse card -->
    <div :id="'collapse_aside-' + id">
      <WCAutoHeightTransition>
        <div
          :class="['card-body', 'pt-1', bodyClass]"
          :style="{ 'background-color': bodyBackground }"
          v-if="!collapsed"
          class="collapse show"
        >
          <slot name="cardBody"></slot>
        </div>
      </WCAutoHeightTransition>
    </div>
    <!-- END : Transition for collapse card -->
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import WCAutoHeightTransition from '../WCAutoHeightTransition/WCAutoHeightTransition.vue';

export default {
  name: 'WCCollapseCard',
  components: {
    WCAutoHeightTransition,
  },
  props: {
    id: {
      type: [Number, String],
      default: () => uuid(),
    },
    isCollapsedOnLoad: {
      type: Boolean,
    },
    headerBackground: {
      type: String,
    },
    bodyBackground: {
      type: String,
    },
    headerClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    isCollapsed: {
      // Prop to handle collapsed status of the card from parent
      type: [Boolean, null],
      default: null,
    },
    openIcon: {
      type: String,
      default: 'plus',
    },
    closeIcon: {
      type: String,
      default: 'minus',
    },
  },
  data() {
    return {
      collapsed: this.isCollapsedOnLoad,
    };
  },
  emits: ['onCollapseOrExpand'],
  watch: {
    /**
     * Update the component collapse card status based on parent collapsed statue changes
     */
    isCollapsed() {
      this.updateCollapsedStatus();
    },
  },
  created() {
    this.updateCollapsedStatus();
  },
  methods: {
    /**
     * Method to toggle collapse card
     */
    toggle() {
      this.collapsed = !this.collapsed;
      this.$emit('onCollapseOrExpand', this.collapsed);
    },
    /**
     * Set collapse card status based on the property value sent from the parent
     */
    updateCollapsedStatus() {
      if (this.isCollapsed != null && this.collapsed !== this.isCollapsed)
        this.collapsed = this.isCollapsed;
    },
  },
  computed: {
    collapsibleIcon() {
      return this.collapsed ? this.openIcon : this.closeIcon;
    },
    collapsibleIconTitle() {
      return this.collapsed ? this.$t('expand') : this.$t('collapse');
    },
  },
};
</script>

<style lang="scss" scoped>
.wc-collapse-header {
  background-color: white;
  border-bottom: 0;
  cursor: pointer;
  user-select: none;
  border: none;
}

.wc-card {
  border-radius: 0.37rem;
  overflow: hidden; /* Makes it so that the border-radius in the card-header is not clipped */
  .wc-card-header--negate-padding {
    padding: 0;
  }
  .wc-card-body-negate--padding {
    padding: 0;
  }
}
</style>
