<template>
  <div class="wc-layout wc-layout--mobile-default">
    <WCMobileHeader />
    <!-- Based on employee status classes are added dynamically -->
    <div class="container-fluid" :class="bodyClass">
      <WCAlertRoot />
      <router-view />
    </div>
    <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
    <WCMobileNavigation v-else-if="!isEmployee" />
  </div>
</template>

<script>
import WCAlertRoot from '@/modules/alerts/components/WCAlertRoot/WCAlertRoot.vue';
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import WCMobileHeader from '@/components/WCMobileHeader/WCMobileHeader.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';
import { mapGetters } from 'vuex';
import IneligibleCartCheckMixin from '@/mixins/IneligibleCartCheckMixin';

export default {
  name: 'MobileDefault',
  components: {
    WCMobileHeader,
    WCAlertRoot,
    WCMobileNavigation,
    WCEmployeeAssociationBanner,
  },
  mixins: [EmployeeMixin, IneligibleCartCheckMixin],
  computed: {
    ...mapGetters({
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
    /**
     * Property to check if multistore
     */
    isMultiStore() {
      return this.$configuration.multistore;
    },
    /**
     * Construct classname for layout body
     */
    bodyClass() {
      if (
        (!this.isEmployee || (this.isEmployee && this.isCustomer)) &&
        ((!this.isMultiStore &&
          this.$configuration.orderTypesEnabled &&
          !this.isCustomerModeScan) ||
          (this.isMultiStore && !this.isCustomerModeScan))
      ) {
        return 'wc-layout__body';
      }
      return 'wc-employee-layout__body';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-default {
  .wc-layout__body {
    padding-top: $mobile-header-padding-top + 0.6rem;
    padding-bottom: $mobile-body-padding-bottom;
  }
  .wc-employee-layout__body {
    padding-top: $mobile-header-padding-top - 2rem;
    padding-bottom: $mobile-body-padding-bottom;
  }
}
</style>
