<template>
  <WCOrderTypeCollapseCard
    headerBackground="var(--secondary-lighter-7)"
    class="border-0 px-2"
    :isCollapsedOnLoad="isCollapsedOnLoad"
    :isCollapsed="isCollapsed && isCollapsedOnLoad"
    :bodyClass="'mb-3'"
    headerClass="row no-gutters text-primary w-100 mb-2"
    openIcon="chevron-down"
    closeIcon="chevron-up"
    @onCollapseOrExpand="$emit('onCollapseOrExpand', $event)"
  >
    <template v-slot:headerTitle>
      <div class="mx-3 w-100 d-flex justify-content-between align-items-baseline">
        <span class="w-25">
          <b>{{ orderTypeObjectFromId(cart.orderType).name }} </b>
        </span>
        <span class="w-25">
          {{ lineItemCount }}
          {{ lineItemCount === 1 ? $t('item') : $t('items') }}
        </span>

        <span class="w-25">{{ $t('subtotal') }}: {{ cart.subTotal | currency }} </span>
        <router-link class="btn btn-primary btn-sm ml-auto" to="/co" @click.native="checkout">
          {{ $t('checkout') }}
        </router-link>
      </div>
    </template>
    <template v-slot:cardBody>
      <WCOrderTypeCollapseCardBody :cart="cart" />
    </template>
  </WCOrderTypeCollapseCard>
</template>

<script>
import WCOrderTypeCollapseCard from '@/components/WCOrderTypeCollapseCard/WCOrderTypeCollapseCard.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import { mapActions, mapGetters } from 'vuex';
import WCOrderTypeCollapseCardBody from '../../../../../components/WCOrderTypeCollapseCard/WCOrderTypeCollapseCardBody/WCOrderTypeCollapseCardBody.vue';

export default {
  components: {
    WCOrderTypeCollapseCard,
    WCOrderTypeCollapseCardBody,
  },
  mixins: [OrderTypesMixin],
  props: {
    cart: {
      type: Object,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
      isLoading: 'cart/isLoading',
      isGuest: 'user/isGuest',
      isGiftCard: 'cart/isGiftCard',
    }),
    displayLineItems() {
      if (this.cart.giftCardItems?.length) {
        return this.cart.giftCardItems;
      }

      return this.cart.lineItems.filter(
        lineItem => (lineItem.type === 1 || lineItem.type === 60) && lineItem.quantity > 0,
      );
    },
    lineItemCount() {
      return this.displayLineItems.length;
    },
    isCollapsedOnLoad() {
      return this.cart.orderType !== this.getActiveOrderTypeId;
    },
  },
  methods: {
    ...mapActions({
      cartDrawerCheckout: 'cart/cartDrawerCheckout',
    }),
    async checkout() {
      if (this.cart.orderType !== this.getActiveOrderTypeId) {
        await this.setActiveOrderType(this.orderTypeObjectFromId(this.cart.orderType));
      }
      this.cartDrawerCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-wrapper-scroll {
  height: rem(250px);
}
</style>
