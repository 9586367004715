<template>
  <div class="wc-carousel" :class="{ 'wc-carousel--snap': snap }" v-if="!isSlider">
    <slot></slot>
  </div>
  <carousel :per-page="perPage" v-else>
    <slot></slot>
  </carousel>
</template>

<script>
import { Carousel } from 'vue-carousel';

export default {
  name: 'WCCarousel',
  props: {
    snap: {
      type: Boolean,
      default: true,
    },
    isSlider: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 2,
    },
  },
  components: {
    Carousel,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-carousel {
  // Horizontal scroll
  overflow-x: auto;
  white-space: nowrap;
  // Default start/end padding
  padding-left: $pad-4;
  padding-right: $pad-4;

  // Carousel content is displayed in a row
  * {
    display: inline-block !important;
  }

  // Enable snapping
  &.wc-carousel--snap {
    // Enable horizontal snapping
    scroll-snap-type: x proximity;
    // Default snap padding
    scroll-padding: $pad-4;

    // Assign snap points to left-edges of carousel content
    * {
      scroll-snap-align: start;
    }
    // Assign one snap point to the right-edge of the last element
    :nth-last-child(1) {
      scroll-snap-align: end;
    }
  }

  // Play nice if inside a bootstrap container
  &.wc-carousel--negate-container {
    margin-left: -$container-gutter;
    margin-right: -$container-gutter;
    padding-left: $container-gutter;
    padding-right: $container-gutter;

    &.wc-carousel--snap {
      scroll-padding: $container-gutter;
    }
  }

  // Play nice if inside a bootstrap row
  &.wc-carousel--negate-padding {
    padding-left: $container-gutter;
    padding-right: $container-gutter;
  }
}
::v-deep .VueCarousel-dot {
  margin-top: 0 !important;
}
::v-deep .VueCarousel-dot:focus {
  outline: none;
}
::v-deep .VueCarousel-dot-container {
  margin-top: 0 !important;
}
::v-deep .VueCarousel-inner {
  padding: 0.2rem;
}
::v-deep .VueCarousel-slide {
  margin-right: 0.2rem;
}
</style>
