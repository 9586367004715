<template>
  <main class="home">
    <div v-if="$configuration.orderTypesEnabled">
      <WCOrderTypes :orderTypes="visibleOrderTypes" />
    </div>
    <template v-else>
      <WCWebAds v-if="$configuration.ads" :webAds="$configuration.ads" />
    </template>

    <WCHomePage :name="$configuration.store.name" :rows="$configuration.rows" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import FocusMixin from '@/mixins/FocusMixin';
import WCOrderTypes from '@/modules/orderTypes/components/WCOrderTypes/WCOrderTypes.vue';
import WCHomePage from '@/views/Home/components/WCHomePage/WCHomePage.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCWebAds from '@/components/WCWebAds/WCWebAds.vue';

export default {
  components: { WCHomePage, WCOrderTypes, WCWebAds },
  name: 'Home',
  computed: {
    ...mapGetters(['getConfiguration']),
  },
  mixins: [FocusMixin, OrderTypesMixin], // Mixin for main content focus via routing
};
</script>
