<template>
  <div class="wc-navbar border-bottom bg-primary">
    <section v-if="isNotAnEmployee" class="container-xl px-3">
      <ul
        v-if="
          isNotAnEmployee &&
            ((getMenu && getMenu.children) || (circularMenu && circularMenu.length))
        "
        class="wc-item-group-menu nav"
      >
        <WCItemGroupsMenu
          :menu="getMenu"
          :wcHeaderHeight="wcHeaderHeight"
          v-if="$route.name !== 'Gift Card'"
          :circularMenu="circularMenu"
        />
      </ul>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import WCItemGroupsMenu from '@/components/WCItemGroupsMenu/WCItemGroupsMenu.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import ItemGroupMixin from '../../modules/filters/mixins/ItemGroupMixin';

export default {
  name: 'WCNavBar',
  components: { WCItemGroupsMenu },
  mixins: [ItemGroupMixin, EmployeeMixin],
  props: {
    wcHeaderHeight: {
      required: false,
      type: String,
      default: '0px',
    },
  },
  data() {
    return {
      circularMenu: [],
    };
  },
  async created() {
    await this.getCircularData(); // Fetch circular data - specials and promotions
  },
  methods: {
    /**
     * Method to fetch circulars for specials and promotions
     */
    async getCircularData() {
      try {
        const response = await axios.get('/api/circulars');
        this.circularMenu = response?.data?.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    getMenu() {
      return this.$configuration.mainMenu;
    },
    /**
     * Construct circular menu items object
     */
    circularMenuItems() {
      return {
        name: this.$t('currentSpecials'),
        children: this.circularMenu,
      };
    },
  },
};
</script>

<style scoped lang="scss">
ul li:first-child > ::v-deep a {
  padding-left: 0;
}

::v-deep .wc-navbar {
  .nav-link {
    font-weight: 600;
    color: #1c1c1c;
    strong {
      font-weight: 700;
    }
    &:hover {
      color: #666666;
    }
  }
}
</style>
