<template>
  <section class="wc-cart-drawer-root">
    <FocusLoop
      ><transition name="wc-cart-drawer">
        <WCCartDrawer
          v-click-outside="close"
          v-if="show"
          :line-items="getCartItems"
          :outofstock-line-items="getOutOfStockLineItems"
          :carts="nonEmptyCarts"
          @keydown.native.esc="close"
        />
      </transition>
    </FocusLoop>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { FocusLoop } from '@vue-a11y/focus-loop';

import ClickOutside from 'vue-click-outside';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import CartDrawerService from '../../services/CartDrawerService';
import WCCartDrawer from '../WCCartDrawer/WCCartDrawer.vue';

export default {
  components: { WCCartDrawer, FocusLoop },
  mixins: [CartItemControls],
  name: 'WCCartDrawerRoot',
  data() {
    return {
      show: false,
    };
  },
  directives: { ClickOutside },
  created() {
    CartDrawerService.$on('cartDrawer:open', () => {
      this.open();
    });

    CartDrawerService.$on('cartDrawer:close', () => {
      this.close();
    });

    CartDrawerService.$on('cartDrawer:toggle', () => {
      this.toggle();
    });
  },
  computed: {
    ...mapGetters({
      getOutOfStockLineItems: 'cart/getOutOfStockLineItems',
      nonEmptyCarts: 'cart/nonEmptyCarts',
    }),
    getCartItems() {
      return this.cartLineItems.filter(item => item.type === 1 || item.type === 60);
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    toggle() {
      this.show = !this.show;
    },
  },
  watch: {
    $route(to, from) {
      if (this.show && to !== from) {
        this.close();
      }
    },
    /**
     * Focus the header shopping cart button on close
     */
    show() {
      if (!this.show) {
        document.getElementById('headerCartButton')?.focus();
      }
    },
  },
};
</script>

<style scoped>
.wc-cart-drawer-enter-active,
.wc-cart-drawer-leave-active {
  transition: all 0.3s ease-in-out;
}

.wc-cart-drawer-enter,
.wc-cart-drawer-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
