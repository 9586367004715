import Vue from 'vue';
import {
  currency,
  formatDateTime,
  formatDate,
  formatTime,
  formatMonthDay,
  dayOfWeek,
  shortDay,
} from './formatters';

export default () => {
  Vue.filter('currency', currency);

  Vue.filter('formatDateTime', formatDateTime);

  Vue.filter('formatDate', formatDate);

  Vue.filter('formatTime', formatTime);

  Vue.filter('formatMonthDay', formatMonthDay);

  Vue.filter('dayOfWeek', dayOfWeek);

  Vue.filter('shortDay', shortDay);
};
