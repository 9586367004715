<template>
  <platform-renderer>
    <template v-slot:onMobile>
      <div class="mobile-selections-content">
        <!-- START : Selections Header -->
        <div class="selections__header">
          <h3 class="selections__title mb-0">{{ $t('currentSelections') }}</h3>
          <!-- START : Clear All Button -->
          <button
            class="btn btn-link underline-link-from-center font-weight-bold font-size-lg mr-0"
            @click="$emit('clearAll')"
          >
            {{ $t('clearAll') }}
          </button>
          <!-- END : Clear All Button -->
        </div>
        <!-- END : Selections Header -->
        <!-- START : Selected List Wrap -->
        <div class="selections__list-wrap mx-3">
          <div class="d-flex justify-content-between flex-wrap mt-3">
            <p>
              <strong>{{ quantityLabel + ': ' }}</strong
              ><span data-testid="quantityDisplay">{{
                getDisplayQuantity(item, modifierQuantity)
              }}</span>
            </p>
            <template v-if="isSoldByRandomWeight(item)">
              <p>
                <strong>{{ $t('quantity') + ': ' }}</strong
                ><span data-testid="weightedQuantityDisplay">{{
                  getDisplayWeightedQuantity(item, modifierWeightedQuantity)
                }}</span>
              </p>
            </template>
          </div>
          <WCCarousel
            class="wc-carousel--negate-container py-3"
            :perPage="itemPerPage"
            :isSlider="true"
          >
            <!-- START : Selected Item Modifiers List -->
            <slide
              class="wc-item-modifier-group"
              v-for="selectedItem in selectedItemList"
              :key="selectedItem.key"
            >
              <WCItemModifierItem
                :item="selectedItem.item"
                :itemModifiers="itemModifiers"
                :prohibitAlterations="selectedItem.category.prohibitAlterations"
                :category="selectedItem.category"
              />
            </slide>
            <!-- END : Selected Item Modifiers List -->
            <slide
              class="wc-item-modifier-group"
              v-for="linkedItem in selectedLinkedItemList"
              :key="linkedItem.key"
            >
              <!-- START : Selected Linked Item Modifiers List -->
              <WCItemModifierLinkedItem
                :linkedItem="linkedItem.item"
                :itemModifiers="itemModifiers"
                :prohibitAlterations="itemModifiers.linkedItems.prohibitAlterations"
              />
            </slide>
            <!-- END : Selected Linked Item Modifiers List -->
          </WCCarousel>
        </div>
        <!-- END : Selected List Wrap -->
      </div>
    </template>
    <template v-slot:onDesktop>
      <div>
        <h4>{{ $t('currentSelections') }}</h4>
        <hr class="ml-0 mr-0" />
        <dl>
          <div class="row p-1 small text">
            <dt class="wc-answer float-left col-8 font-weight-normal">
              {{ quantityLabel }}
            </dt>
            <dd data-testid="quantityDisplay" class="float-right col-4">
              {{ getDisplayQuantity(item, modifierQuantity) }}
            </dd>
            <template v-if="isSoldByRandomWeight(item)">
              <dt class="wc-answer float-left col-8 font-weight-normal">
                {{ $t('quantity') }}
              </dt>
              <dd data-testid="weightedQuantityDisplay" class="float-right col-4">
                {{ getDisplayWeightedQuantity(item, modifierWeightedQuantity) }}
              </dd>
            </template>
          </div>
          <div class="row p-1 small text" v-for="answer in visibleAnswers" :key="answer.id">
            <dt
              class="wc-answer float-left col-8 font-weight-normal"
              :class="{
                'wc-answer--subtraction': answer.subtraction,
              }"
            >
              {{ answer.label }}
            </dt>
            <dd v-if="showPrice(answer)" class="float-right col-4">
              +{{ addOnExtendedPrice(answer.priceModifier) | currency }}
            </dd>
          </div>
        </dl>
      </div>
    </template>
  </platform-renderer>
</template>

<script>
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import WCItemModifierItem from '@/modules/itemModifiers/components/WCItemModifierItem/WCItemModifierItem.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCItemModifierLinkedItem from '@/modules/itemModifiers/components/WCItemModifierLinkedItem/WCItemModifierLinkedItem.vue';
import { BigNumber } from 'bignumber.js';
import { Slide } from 'vue-carousel';
import { PlatformMixin } from '@/modules/platform';

export default {
  name: 'WCItemModifierAnswers',
  components: {
    PlatformRenderer,
    WCCarousel,
    WCItemModifierItem,
    WCItemModifierLinkedItem,
    Slide,
  },
  mixins: [ItemModifierMixin, CartControls, ItemModifierQuantityMixin, PlatformMixin],
  emits: ['clearAll'],
  computed: {
    /**
     * Method to get the selectd item group
     * @return {Array} - Array of items with key item, category, key
     */
    selectedItemList() {
      let itemList = this.visibleAnswers.map(answer => {
        const itemId = answer.id;
        let itemObj = null;
        let categoryObj = null;
        let result = null;
        const isDefaultItemRemoved = answer.default && new BigNumber(answer.quantity || 0).isZero();
        if (!isDefaultItemRemoved) {
          this.categories.forEach(category => {
            if (!itemObj) {
              itemObj = category.items.find(item => item.id === itemId);
              categoryObj = category;
            }
          });

          result = itemObj
            ? {
                key: answer.id,
                item: itemObj,
                category: categoryObj,
              }
            : null;
        }

        return result;
      });
      // To remove Null Element on Array
      itemList = itemList.filter(value => value);
      return itemList;
    },
    /**
     * Method to get the selectd linked item group
     * @return {Array} - Array of items with key item, key
     */
    selectedLinkedItemList() {
      let itemsList = [];
      if (this.itemModifiers.linkedItems) {
        const currentAnswersDetails = this.visibleAnswers.map(answer => {
          const itemId = answer.id;
          let result = null;
          const isDefaultItemRemoved =
            answer.default && new BigNumber(answer.quantity || 0).isZero();
          if (!isDefaultItemRemoved) {
            const itemObj = (this.itemModifiers.linkedItems.items || []).find(
              item => item.id === itemId,
            );
            result = itemObj
              ? {
                  key: answer.id,
                  item: itemObj,
                }
              : null;
          }
          return result;
        });
        // To remove Null Element on Array
        itemsList = currentAnswersDetails.filter(value => value);
      }
      return itemsList;
    },
    itemPerPage() {
      return this.isMediumDevice ? 4 : 2;
    },
    quantityLabel() {
      if (this.isSoldByRandomWeight(this.item)) {
        return this.$t('weight');
      }
      return this.$t('quantity');
    },
  },
  methods: {
    showPrice(answer) {
      return !answer.subtraction;
    },
    addOnExtendedPrice(price) {
      return price * this.addOnPriceMultiplier;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-answer {
  display: flex;
  flex-direction: column;

  &--subtraction {
    color: var(--red);
  }
}

/*----- START : Mobile Current Selections Wrap -----*/
.mobile-selections-content {
  /*----- START : Mobile Current Selections Header -----*/
  .selections__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
    padding: $pad-1 $pad-3;
    color: var(--secondary-lighter-7-contrast, get-color-contrast('secondary', 'lighter-7'));
  }
  /*----- END : Mobile Current Selections Header -----*/

  /*----- START : carousel on click scroll issue fix -----*/
  ::v-deep .wc-carousel.wc-carousel--snap {
    scroll-snap-type: none;
  }
  /*----- END : carousel on click scroll issue fix -----*/

  /*----- START : Item Modifier Group -----*/
  .wc-item-modifier-group > * {
    width: 90%;
  }
  /*----- END : Item Modifier Group -----*/
}
/*----- END : Mobile Current Selections Wrap -----*/
</style>
