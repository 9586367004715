<template>
  <div class="toast-body">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WCToastBody',
};
</script>

<style lang="scss" scoped></style>
