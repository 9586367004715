<template>
  <div>
    <div v-if="!isLoading && !isCartsLoading">
      <div class="container-fluid" v-for="lineItem in cart.lineItems" :key="lineItem.id">
        <div>
          <WCCartDrawerItem
            v-if="lineItem.type === 1 && lineItem.quantity > 0"
            :line-item="lineItem"
          />
        </div>
        <div>
          <WCCartDrawerSpecialOrderItem
            v-if="lineItem.type === 60 && lineItem.quantity > 0"
            :soItem="lineItem"
          />
        </div>
      </div>
      <div>
        <WCCartDrawerGiftCard :cart="cart" />
      </div>
    </div>

    <WCSpinner
      v-else-if="isCartsLoading || isLoading"
      class="mx-auto align-self-center"
      size="xl"
      position="center"
    />
  </div>
</template>

<script>
import WCCartDrawerItem from '@/modules/cart/components/WCCartDrawerItem/WCCartDrawerItem.vue';
import { mapGetters } from 'vuex';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import WCCartDrawerSpecialOrderItem from '@/modules/cart/components/WCCartDrawerSpecialOrderItem/WCCartDrawerSpecialOrderItem.vue';
import WCCartDrawerGiftCard from '../../WCCartDrawerGiftCard/WCCartDrawerGiftCard.vue';

export default {
  components: {
    WCCartDrawerItem,
    WCCartDrawerGiftCard,
    WCSpinner,
    WCCartDrawerSpecialOrderItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    cart: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'cart/isLoading',
      isCartsLoading: 'cart/cartsLoadingStatus',
    }),
  },
};
</script>
