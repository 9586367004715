<template>
  <div>
    <WCCollapseCardGroup
      :count="carts.length"
      :initialActiveIdx="activeOrderTypeIndex"
      :oneActiveCard="true"
      :hasBorderRadius="false"
    >
      <template v-slot:default="cardGroupSlotProps">
        <div v-for="(cart, i) in carts" :key="cart.id">
          <WCCartDrawerOrderType
            :cart="cart"
            :isCollapsed="cardGroupSlotProps.collapseCardStatusList[i]"
            @onCollapseOrExpand="cardGroupSlotProps.updateCollapseCard(i, $event)"
          />
        </div>
      </template>
    </WCCollapseCardGroup>
  </div>
</template>

<script>
import WCCollapseCardGroup from '@/components/WCCollapseCardGroup/WCCollapseCardGroup.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCCartDrawerOrderType from './WCCartDrawerOrderType.vue';

export default {
  components: { WCCollapseCardGroup, WCCartDrawerOrderType },
  props: {
    carts: {
      type: Array,
      required: true,
    },
  },
  mixins: [OrderTypesMixin],
  computed: {
    activeOrderTypeIndex() {
      return this.carts.findIndex(cart => cart.orderType === this.activeOrderType.id);
    },
  },
};
</script>
<style scoped></style>
