<!-- Information quick links options inside side menu from custom sources configuration -->
<template>
  <WCSideMenuCollapsibleTemplate>
    <template v-slot:menuHeader>
      <h3 class="mb-0 text-dark">{{ $t('quickLinks') }}</h3>
    </template>
    <template v-slot:menuBody>
      <ul>
        <WCSideMenuItem v-for="cs in $configuration.customSources" :key="cs.id">
          <router-link class="underline-link-from-center" :to="`/cs/${cs.id}`">{{
            cs.label
          }}</router-link>
        </WCSideMenuItem>
      </ul>
    </template>
  </WCSideMenuCollapsibleTemplate>
</template>

<script>
import WCSideMenuCollapsibleTemplate from '../WCSideMenuCollapsibleTemplate/WCSideMenuCollapsibleTemplate.vue';
import WCSideMenuItem from '../WCSideMenuItem/WCSideMenuItem.vue';

export default {
  name: 'WCInformationCollapsibleMenu',
  components: { WCSideMenuCollapsibleTemplate, WCSideMenuItem },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h3,
a {
  color: var(--dark, $dark);
}
</style>
