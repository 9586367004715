<template>
  <div class="wc-layout wc-layout--desktop-default d-flex flex-column">
    <WCHeader />
    <section class="wc-layout__body container py-3 flex-grow-1">
      <WCAlertRoot />
      <slot>
        <router-view />
      </slot>
    </section>
    <WCFooter class="flex-shrink-0 mt-5" />
  </div>
</template>

<script>
import WCAlertRoot from '@/modules/alerts/components/WCAlertRoot/WCAlertRoot.vue';
import IneligibleCartCheckMixin from '@/mixins/IneligibleCartCheckMixin';
import WCFooter from '../components/WCFooter/WCFooter.vue';
import WCHeader from '../components/WCHeader/WCHeader.vue';

export default {
  name: 'DesktopDefault',
  components: { WCHeader, WCFooter, WCAlertRoot },
  mixins: [IneligibleCartCheckMixin],
};
</script>
<style lang="scss" scoped>
.wc-layout--desktop-default {
  min-height: 100%;
}
</style>
