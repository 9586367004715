import axios from 'axios';

export const LIST_TYPES = {
  CUSTOM: 'CUSTOM',
  FAVORITES: 'FAVORITES',
  SAVE_FOR_LATER: 'SAVE_FOR_LATER',
};

/**
 * Constant to hold predefined list informations
 */
export const PREDEFINED_LIST_INFO = [
  {
    type: LIST_TYPES.FAVORITES,
    name: 'Favorites',
  },
  {
    type: LIST_TYPES.SAVE_FOR_LATER,
    name: 'Save for later',
  },
];

const state = {
  lists: [],
  detailList: {},
  loadingStatus: false,
  activeShoppingList: {},
  isActiveShoppingListCompleted: false,
  shoppingListCount: 0,
};

const getters = {
  getLists(state) {
    return state.lists ? state.lists : [];
  },
  getDetailList(state) {
    return state.detailList ? state.detailList : {};
  },
  getFavoritesList(state) {
    const [favorites] = getters.getListsByType(state)(LIST_TYPES.FAVORITES);
    return favorites;
  },
  getSaveForLaterList(state) {
    const [saveForLater] = getters.getListsByType(state)(LIST_TYPES.SAVE_FOR_LATER);
    return saveForLater;
  },
  getCustomLists(state) {
    const custom = getters.getListsByType(state)(LIST_TYPES.CUSTOM);
    return custom;
  },
  isFavorited(state) {
    return item => {
      if (item) {
        const favorites = getters.getFavoritesList(state);
        return favorites && favorites.items
          ? favorites.items.some(i => i.id === item.id && i.quantity > 0)
          : false;
      }

      return false;
    };
  },
  find(state) {
    return list => {
      if (list) {
        if (list.id) {
          return getters.getListById(state)(list.id);
        }

        if (list.type && list.type === LIST_TYPES.FAVORITES) {
          return getters.getFavoritesList(state);
        }
        if (list.type && list.type === LIST_TYPES.SAVE_FOR_LATER) {
          return getters.getSaveForLaterList(state);
        }
        if (list.type && list.type === LIST_TYPES.CUSTOM) {
          return getters.getCustomLists(state);
        }
      }

      return null;
    };
  },
  getListById(state) {
    return id => {
      const list = state.lists.find(l => l.id === id);
      return list;
    };
  },
  getListsByType(state) {
    return type => {
      const lists = state.lists.filter(l => l.type === type);
      return lists;
    };
  },
  isLoading(state) {
    return state.loadingStatus;
  },
  /**
   * Get active shopping list
   * @param {*} state
   * @returns Object
   */
  getActiveShoppingList(state) {
    return state.activeShoppingList ? state.activeShoppingList : {};
  },

  /**
   * Get shopping list count
   * @param {*} state
   * @returns Object
   */
  getShoppingListCount(state) {
    return state.shoppingListCount ? state.shoppingListCount : {};
  },

  /**
   * Get active shopping list completed status
   * @param {*} state
   * @returns Boolean
   */
  getActiveShoppingListCompleted(state) {
    return state.isActiveShoppingListCompleted ? state.isActiveShoppingListCompleted : false;
  },
  getAvailableItemsList(state) {
    return (state.lists && state.lists.filter(l => l.items && l.items.length)) || [];
  },
};

export const SET_LISTS = 'SET_LISTS';
export const ADD_LIST = 'ADD_LIST';
export const REMOVE_LIST = 'REMOVE_LIST';
export const ADD_ITEM = 'ADD_ITEM';
export const ADD_LIST_ITEMS = 'ADD_LIST_ITEMS';
export const SET_ITEMS = 'SET_ITEMS';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const SET_DETAILS = 'SET_DETAILS';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_ACTIVE_SHOPPING_LISTS = 'SET_ACTIVE_SHOPPING_LISTS';
export const SET_ACTIVE_SHOPPING_LIST_COMPLETED = 'SET_ACTIVE_SHOPPING_LIST_COMPLETED';
export const SET_SHOPPING_LIST_COUNT = 'SET_SHOPPING_LIST_COUNT';

const mutations = {
  [SET_LISTS](state, lists) {
    state.lists = lists || [];
  },
  [ADD_LIST](state, list) {
    state.lists = [...state.lists.filter(l => l.id !== list.id), list];
  },
  [REMOVE_LIST](state, id) {
    state.lists = state.lists.filter(list => list.id !== id);
  },
  [ADD_ITEM](state, payload) {
    const { list, item, variation } = payload;
    const match = getters.find(state)(list);
    if (match) {
      const items = match.items ? match.items : [];
      match.items = [
        ...items.filter(i => {
          return variation ? i.id !== item.id && i.variation !== variation : i.id !== item.id;
        }),
        { id: item.id, quantity: 1, variation, weightedItemQuantity: 0 },
      ];
    }
  },
  [ADD_LIST_ITEMS](state, payload) {
    const { list, items } = payload;
    const match = getters.find(state)(list);
    if (match) {
      const matchLineItems = items.filter(item => item.type === 1);
      match.items = matchLineItems.map(item => {
        return {
          id: item.item.id,
          quantity: 1,
          variation: item.variation,
          weightedItemQuantity: 0,
        };
      });
    }
  },
  [REMOVE_ITEM](state, payload) {
    const { list, item, variation } = payload;

    const match = getters.find(state)(list);
    const items = match.items ? match.items : [];
    // Setting the quantity of the item to 0 will remove it from the list
    match.items = [
      ...items.filter(i => {
        return variation ? i.id !== item.id && i.variation !== variation : i.id !== item.id;
      }),
      { id: item.id, quantity: 0, variation },
    ];
  },
  [REMOVE_ITEMS](state, payload) {
    const { list, items } = payload;
    const match = getters.find(state)(list);
    if (match.items) {
      match.items = match.items.map(item => {
        // included variation also - so that will be reliable for MTO items add to list

        if (item.variation) {
          if (items.some(i => item.id === i.item.id && item.variation === i.variation.id)) {
            // Setting the quantity of the item to 0 will remove it from the list
            return { ...item, quantity: 0 };
          }
        } else if (items.some(i => item.id === i.item.id)) {
          return { ...item, quantity: 0 };
        }

        return item;
      });
    }
  },
  [SET_DETAILS](state, payload) {
    const { list, details } = payload;

    // Set the detailed list on the state to be the detailed list
    state.detailList = details;

    const match = getters.find(state)(list);

    if (match && details && details.items) {
      // Set matched list to be the basicItemList version of details
      match.items = details.items.map(i => ({
        variation: i.variation && i.variation.id ? i.variation.id : i.variation,
        id: i.item.id,
        image: i.item.image,
        name: i.item.name,
        quantity: i.quantity,
        sumQuantity: i.item.sumQuantity,
        weightedItemQuantity: i.item.weightedItemQuantity,
      }));
    }
  },

  [SET_LOADING_STATUS](state, status) {
    state.loadingStatus = status;
  },

  /**
   * Update active shopping list into store
   * @param {*} state
   * @param {*} activeShoppingList
   */
  [SET_ACTIVE_SHOPPING_LISTS](state, activeShoppingList) {
    state.activeShoppingList = activeShoppingList || {};
  },

  /**
   * Update active shopping list completed status into store
   * @param {*} state
   * @param {*} data
   */
  [SET_ACTIVE_SHOPPING_LIST_COMPLETED](state, data) {
    state.isActiveShoppingListCompleted = data || false;
  },

  /**
   * Update shopping list count status into store
   * @param {*} state
   * @param {*} data
   */
  [SET_SHOPPING_LIST_COUNT](state, data) {
    state.shoppingListCount = data || 0;
  },
};

/**
 * Method to populate pre-defined shopping lists
 * @param {Array} lists
 * @returns Array - shoppingLists
 */
const populatePreDefinedLists = (lists = []) => {
  // Add pre-defined lists
  const predefinedLists = [];
  const existingPredefinedListIndexes = []; // Existing Predefined list of indexes are noted in this array
  PREDEFINED_LIST_INFO.forEach(listType => {
    // Find predefined lists from the response
    const predefinedListsIdx = lists.findIndex(list => list.type === listType.type);
    if (predefinedListsIdx === -1) {
      // If the predefined list type is not present, make an entry in predefinedLists
      predefinedLists.push({
        count: 0,
        items: [],
        type: listType.type,
        name: listType.name,
      });
    } else {
      // If the predefined list type is present, push the list information into the predefinedLists
      predefinedLists.push({ ...lists[predefinedListsIdx] });
      existingPredefinedListIndexes.push(predefinedListsIdx);
    }
  });
  // Make the new list out of pre-defined lists and then custom lists from the response
  return [
    ...predefinedLists,
    ...lists.filter((list, index) => !existingPredefinedListIndexes.includes(index)),
  ];
};

const actions = {
  async fetch({ commit }) {
    try {
      commit(SET_LOADING_STATUS, true);
      const response = await axios.get('api/me/lists');
      const lists = populatePreDefinedLists(response.data.data) || [];
      commit(SET_LISTS, lists);
      commit(SET_LOADING_STATUS, false);
    } catch (error) {
      console.error(error);
    }
  },
  async remove({ commit }, id) {
    try {
      commit(SET_LOADING_STATUS, true);
      const response = await axios.delete(`api/me/lists/${id}`);
      commit(SET_LOADING_STATUS, false);
      commit(REMOVE_LIST, id);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async add({ commit, dispatch }, payload) {
    try {
      commit(SET_LOADING_STATUS, true);
      const response = await axios.post('api/me/lists', payload);
      const { id, name, items, count } = response.data;

      const type = 1;

      const list = { id, name, items, count, type };

      commit(ADD_LIST, list);
      await dispatch('fetch');
      commit(SET_LOADING_STATUS, false);
      return list;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addListItem({ dispatch, commit, getters }, payload) {
    const { list, item, variation, details = true } = payload;
    try {
      const match = getters.find(list);
      if (match && variation != null) {
        commit(ADD_ITEM, { list: match, item, variation });
      } else {
        commit(ADD_LIST, list);
        commit(ADD_ITEM, { list, item, variation });
      }

      const response = await axios.post('api/me/lists', {
        ...getters.find(list),
      });
      await dispatch('fetch');

      if (details) {
        dispatch('loadDetails', { list });
      }
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Add Multiple items to list at once
  async addListItems({ dispatch, commit, getters }, payload) {
    const { list, items } = payload;
    try {
      const match = getters.find(list);

      if (match) {
        commit(ADD_LIST_ITEMS, { list: match, items });
      } else {
        commit(ADD_LIST, list);
        commit(ADD_LIST_ITEMS, { list, items });
      }

      const response = await axios.post('api/me/lists', {
        ...getters.find(list),
      });
      await dispatch('fetch');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async removeListItem({ dispatch, commit, getters }, payload) {
    try {
      const { list, item, variation } = payload;

      const match = getters.find(list);

      if (!match) {
        return false;
      }

      commit(REMOVE_ITEM, { list, item, variation });
      const response = await axios.post('api/me/lists', {
        ...getters.find(list),
      });
      await dispatch('fetch');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async removeListItems({ dispatch, commit, getters }, payload) {
    try {
      const { list, items, variation } = payload;
      const match = getters.find(list);

      if (!match) {
        return false;
      }

      commit(REMOVE_ITEMS, { list, items, variation });

      const response = await axios.post('api/me/lists', {
        ...getters.find(list),
      });
      await dispatch('fetch');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async loadDetails({ commit, getters }, payload) {
    commit(SET_LOADING_STATUS, true);
    const { list } = payload;
    let detailedList = {
      list: {},
      details: {},
    };
    if (list && list.id) {
      const match = getters.find(list);
      if (match && match.id) {
        const response = await axios.get(`api/me/lists/${match.id}`);
        detailedList = { list: payload.list, details: response.data };
      }
    }
    commit(SET_DETAILS, detailedList);
    commit(SET_LOADING_STATUS, false);
    return this.list;
  },

  /**
   * Updating inital and updated API data into active shopping list
   * @param { dispatch, state} store
   * @param {*} payload
   * @returns void
   */
  async updateActiveShoppingListItems({ dispatch, state }, payload) {
    const activeShoppingList = state.activeShoppingList;
    if (!activeShoppingList || !Object.keys(activeShoppingList).length || !payload) {
      dispatch('processActiveShoppingList', payload);
      return;
    }

    const payloadData = payload.items;
    const activeShoppingListItems = activeShoppingList.items;
    let hasItemUpdate = false;
    if (activeShoppingListItems && activeShoppingListItems.length && payloadData) {
      payloadData.forEach(data => {
        const shoppingItem = activeShoppingListItems.find(i => data.item.id === i.item.id);
        if (!shoppingItem) {
          activeShoppingListItems.unshift(data);
          hasItemUpdate = true;
        }
      });
      activeShoppingListItems.forEach((data, index) => {
        const shoppingItem = payloadData.find(i => data.item.id === i.item.id);
        if (!shoppingItem) {
          activeShoppingListItems.splice(index, 1);
          hasItemUpdate = true;
        }
      });
    }

    if (hasItemUpdate) {
      activeShoppingList.items = activeShoppingListItems;
      dispatch('processActiveShoppingList', activeShoppingList);
    }
  },

  /**
   * Updated selection progress to active shopping list
   * @param {dispatch} state
   * @param {*} payload
   */
  async updateActiveShoppingList({ dispatch }, payload) {
    dispatch('processActiveShoppingList', payload);
  },

  /**
   * Process active shopping list with cart items and sort and shopping list completed verification
   * @param {commit, rootGetters} state
   * @param {*} payload
   * @returns void
   */
  async processActiveShoppingList({ commit, rootGetters, dispatch }, payload) {
    try {
      if (!payload) {
        commit(SET_ACTIVE_SHOPPING_LISTS, payload);
        commit(SET_SHOPPING_LIST_COUNT, 0);
        return;
      }

      const cartItems = rootGetters['cart/getLineItems'];
      const activeShoppingList = payload;
      if (activeShoppingList && Object.keys(activeShoppingList).length) {
        const itemList = activeShoppingList.items.map(itemObj => {
          const item = { ...itemObj };
          const cartItem = cartItems && cartItems.find(i => i.item?.id === item.item?.id);
          item.isSelected = item.listItemSelected;
          if (cartItem && !item.isManuallyUnSelected) {
            item.isSelected = true;
            item.isManuallySelected = false;
          } else {
            if (!cartItem) {
              item.isManuallyUnSelected = false;
            }
            item.isSelected = item.isManuallySelected || false;
          }
          return item;
        });

        itemList.sort((x, y) => {
          if (x.isSelected === y.isSelected) return 0;
          return x.isSelected ? 1 : -1;
        });
        activeShoppingList.items = itemList;
        commit(SET_ACTIVE_SHOPPING_LISTS, activeShoppingList);
        commit(SET_SHOPPING_LIST_COUNT, activeShoppingList.items.length);

        const listCompleted = activeShoppingList.items.find(l => !l.isSelected);
        commit(SET_ACTIVE_SHOPPING_LIST_COMPLETED, !listCompleted);

        let hasItemListUpdate = false;
        const listItemsUpdate = activeShoppingList.items.map(itemObj => {
          const item = { ...itemObj };
          item.id = item.item.id;
          item.name = item.item.name;
          item.image = item.item.image;
          if (item.isSelected !== item.listItemSelected) {
            hasItemListUpdate = true;
            item.listItemSelected = item.isSelected;
          }
          delete item.isManuallySelected;
          delete item.isManuallyUnSelected;
          delete item.isSelected;
          delete item.item;
          return item;
        });

        if (hasItemListUpdate) {
          const activeShoppingListUpdate = { ...activeShoppingList };
          delete activeShoppingListUpdate.code;
          activeShoppingListUpdate.items = listItemsUpdate;
          dispatch('add', activeShoppingListUpdate);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
