import { render, staticRenderFns } from "./WCCouponEligibleItemDetails.vue?vue&type=template&id=22e25076&scoped=true"
import script from "./WCCouponEligibleItemDetails.vue?vue&type=script&lang=js"
export * from "./WCCouponEligibleItemDetails.vue?vue&type=script&lang=js"
import style0 from "./WCCouponEligibleItemDetails.vue?vue&type=style&index=0&id=22e25076&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e25076",
  null
  
)

export default component.exports