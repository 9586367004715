import axios from 'axios';

const state = {
  suggestions: [],
};

const getters = {
  getSuggestions(state) {
    return state.suggestions;
  },
};

export const SET_SUGGESTIONS = 'SET_SUGGESTIONS';

const mutations = {
  [SET_SUGGESTIONS](state, suggestions) {
    state.suggestions = suggestions;
  },
};

const actions = {
  fetchSuggestions({ commit }, query) {
    return axios
      .get('/api/b/suggest', {
        params: {
          q: query,
        },
      })
      .then(res => {
        const data = res.data;
        const suggestions = data.map(suggestion => {
          return JSON.parse(suggestion);
        });
        commit(SET_SUGGESTIONS, suggestions);
      })
      .catch(err => {
        console.log(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
