import { render, staticRenderFns } from "./WCItemModifierItemGroup.vue?vue&type=template&id=da917e92&scoped=true"
import script from "./WCItemModifierItemGroup.vue?vue&type=script&lang=js"
export * from "./WCItemModifierItemGroup.vue?vue&type=script&lang=js"
import style0 from "./WCItemModifierItemGroup.vue?vue&type=style&index=0&id=da917e92&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da917e92",
  null
  
)

export default component.exports