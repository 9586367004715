<template>
  <platform-renderer v-if="visibleWebAds.length">
    <template v-slot:onMobile>
      <div class="wc-mobile-ads d-flex mx-n3" v-if="visibleWebAds.length == 1">
        <WCWebAd v-for="webAd in visibleWebAds" :key="webAd.id" :web-ad="webAd" class="mr-2" />
      </div>
      <WCCarousel
        class="wc-carousel--negate-container py-2"
        :perPage="itemPerPage"
        :isSlider="true"
        v-else
      >
        <slide v-for="webAd in visibleWebAds" :key="webAd.id" style="padding-right: 1.2rem;">
          <WCWebAd :web-ad="webAd" :isMobile="true" class=" mr-2" />
        </slide>
      </WCCarousel>
    </template>

    <template v-slot:onDesktop>
      <div class="wc-web-ads d-flex mx-n3">
        <WCWebAd v-for="webAd in visibleWebAds" :key="webAd.id" :web-ad="webAd" class="mx-3" />
      </div>
    </template>
  </platform-renderer>
</template>

<script>
import { Slide } from 'vue-carousel';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import { PlatformMixin } from '@/modules/platform';
import WCCarousel from '../WCCarousel/WCCarousel.vue';
import WCWebAd from '../WCWebAd/WCWebAd.vue';

export default {
  name: 'WCWebAds',
  components: { PlatformRenderer, WCCarousel, WCWebAd, Slide },
  props: {
    webAds: {
      type: Array,
    },
  },
  mixins: [PlatformMixin],
  computed: {
    /**
     * Show only the first three web ads
     */
    visibleWebAds() {
      return this.webAds.slice(0, 3);
    },
    itemPerPage() {
      return this.isMediumDevice ? 2 : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.wc-web-ads {
  height: 10rem;
}
.wc-mobile-ads {
  height: 8rem;
  padding-left: 0.5rem;
}
</style>
