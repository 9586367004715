<template>
  <!-- START : Mobile Product Card Wrap -->
  <div class="wc-card__wrapper">
    <!-- START : Add To Cart Button -->
    <!-- Placed component here because overflow-auto messes it up if nested deeper -->
    <WCBasicAddToCartButton
      v-show="showAddToCartButton"
      class="quantity-overlay flex-column end-0"
      :item="item"
      :dropdownVariant="false"
      :addToListVariant="addToListVariant"
      v-click-outside="hide"
    />
    <!-- END : Add To Cart Button -->

    <!-- START : Product Card -->
    <div class="wc-card shadow-sm rounded overflow-auto">
      <!-- START : Product Card Top -->
      <div class="wc-card__top d-flex flex-column align-items-center justify-content-center">
        <!-- START : Sign In Banner for Hide Price Until Login Feature -->
        <div class="wc-hide-price-sign-in font-size-s" v-if="shouldHideItemPrices">
          <router-link class="badge badge-primary badge-pill" to="/login">{{
            $t('signInToShop')
          }}</router-link>
        </div>
        <!-- END : Sign In Banner -->

        <!-- START : Top Button Section -->
        <WCFavoriteButton
          class="wc-card__heart-icon ml-1 mt-1 p-1"
          v-if="isCustomer"
          :item="item"
          :isMobile="true"
        />
        <!-- Increment button - On clicking plus icon add item to cart -->
        <template v-if="showIncrementButton && !shouldHideItemPrices">
          <button
            v-if="$configuration.orderTypesEnabled || (!isItemInCart(item) && !isMadeToOrderItem)"
            @click="quantityClickedIncrement(item)"
            :aria-label="$t('addToCart')"
            class="unstyled-btn wc-card__add-icon mr-2 mt-2"
          >
            <font-awesome-icon icon="plus" size="lg" />
          </button>
          <!-- End increment button -->

          <!-- Customize button -->
          <WCCustomizeButton
            :item="item"
            class="wc-card__add-icon mr-2 mt-2 btn-primary"
            v-else-if="isMadeToOrderItem"
          />
        </template>
        <!-- Quantity badge - On clicking the badge, quantity adjuster is shown -->
        <button
          class="unstyled-btn quantity-badge mr-2 mt-2 font-size-sm"
          @click.stop="quantityClicked"
          v-if="
            isItemInCart(item) &&
              $configuration.cartEnabled &&
              !isItemListOnly(item) &&
              !$configuration.orderTypesEnabled
          "
        >
          <!-- Screen reader only visible label for quantity  and live region for quantity updates -->
          <span class="sr-only" aria-live="assertive"> {{ $t('quantity') }}</span
          >{{ getDisplayQuantityInCart(item) }}
        </button>
        <!-- END : Top Button Section -->

        <!-- START : Product Image -->
        <router-link
          :to="`/i/${item.id}`"
          class="d-flex flex-column align-items-center justify-content-center h-100 w-100"
        >
          <WCImage
            :imageSrc="getProductImage(item.image)"
            :imageAlt="productImgAltText(item.name, item.brand)"
            imageClass="wc-card__img"
            defaultClass="wc-card__camera-icon"
            :defaultTitle="noProductImageAltText(item.name)"
          />
        </router-link>
        <!-- END : Product Image -->

        <!-- START : Product Promo Ribbon Wrap -->
        <div class="wc-promo-ribbon-wrap">
          <WCPromoRibbon :product="item" :ribbonType="discountNameRibbon" />
        </div>
        <!-- END : Product Promo Ribbon Wrap -->
      </div>
      <!-- END : Product Card Top -->

      <!-- START : Product Card Body -->
      <div class="wc-card__body d-flex font-size-xs p-2">
        <div class="wc-card__name flex-grow-1 text-wrap pr-2 text-break" v-on:click.stop>
          <router-link
            :to="`/i/${item.id}`"
            class="underline-link-from-center font-weight-bold multiline-ellipsis"
            ref="itemLink"
            :aria-label="`Product ${item.name}`"
          >
            {{ item.name }}{{ item.size ? `, ${item.size}` : '' }}
          </router-link>
        </div>
        <div class="wc-card__price text-right" v-if="!shouldHideItemPrices">
          <WCProductPricing
            class="wc-card__item-pricing text-truncate"
            :item="item"
          ></WCProductPricing>
        </div>
      </div>
      <!-- END : Product Card Body -->

      <!-- START : Product Card Footer -->
      <div
        class="wc-card__footer d-flex flex-column font-size-xs flex-grow-1 justify-content-between"
      >
        <div class="d-flex flex-row justify-content-between">
          <div class="wc-card__brand text-truncate ml-2 mb-1">
            <router-link
              v-if="item.brand"
              :to="`/b?g=BRD-${item.brandId}`"
              class="underline-link-from-center"
              :aria-label="`Brand ${item.brand}`"
            >
              {{ item.brand }}
            </router-link>
          </div>
          <font-awesome-icon
            v-if="isCustomer && $configuration.allowCouponClipping && hasCoupons"
            class="wc-coupon-card-mobile__coupon-icon link-primary mr-2 pb-1"
            icon="scissors"
            size="lg"
            @click.stop="openCouponsModal"
          />
        </div>
      </div>
      <!-- END : Product Card Footer -->
    </div>
    <!-- END : Product Card -->
  </div>
  <!-- END : Mobile Product Card Wrap -->
</template>

<script>
import WCFavoriteButton from '@/modules/lists/components/WCFavoriteButton/WCFavoriteButton.vue';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import { mapGetters, mapActions } from 'vuex';
import ClickOutside from 'vue-click-outside';
import { PROMO_RIBBON_TYPE } from '@/constants/PromoRibbonConstants';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import WCPromoRibbon from '@/components/WCPromoRibbon/WCPromoRibbon.vue';
import WCCustomizeButton from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButton.vue';
import WCImage from '@/components/WCImage/WCImage.vue';
import ExitScanAndGo from '@/views/ScanProduct/ExitScanAndGo/ExitScanAndGo.vue';
import ModalService from '@/modules/modals/services/modal.service';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import ToastService from '@/modules/toasts/services/ToastService';
import WCCouponsModal from '@/modules/coupons/WCCouponsModal/WCCouponsModal.vue';
import WCSimpleToastVue from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCAddToOrderTypeCartToast from '@/modules/toasts/components/WCAddToOrderTypeCartToast/WCAddToOrderTypeCartToast.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import HidePricingMixin from '@/mixins/HidePricingMixin';
import { BTN_VARIENT as ATLB_BTN_VARIANT } from '@/modules/lists/components/WCAddToListButton/WCAddToListButton.vue';

export default {
  name: 'WCMobileProductCard',
  components: {
    WCBasicAddToCartButton: () =>
      import('@/modules/cart/components/WCBasicAddToCartButton/WCBasicAddToCartButton.vue'),
    WCFavoriteButton,
    WCProductPricing,
    WCPromoRibbon,
    WCCustomizeButton,
    WCImage,
  },
  directives: { ClickOutside },
  mixins: [
    CartControlsMixin,
    ProductImageInfoMixin,
    OrderTypesMixin,
    ProductDetailMixin,
    HidePricingMixin,
  ],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isCustomerModeScan: 'user/isCustomerModeScan',
      isInStore: 'cart/getInStore',
    }),
    addToListVariant() {
      return ATLB_BTN_VARIANT.MOBILE_CARD;
    },
    isMadeToOrderItem() {
      return this.item.hasModifiers || this.item.hasLinkedItems;
    },
    /**
     * Method to get the discount name ribbon key
     */
    discountNameRibbon() {
      return PROMO_RIBBON_TYPE.DISCOUNT_NAME_RIBBON;
    },
    // todo Deduplicate this from WCBasicAddToCartButton
    inStoreOnly() {
      return this.isInStoreOnly(this.item) && !this.isScanAndGoMode;
    },
    notAvailable() {
      return this.getMinimumQuantity(this.item).comparedTo(0) <= 0;
    },
    hasCoupons() {
      return this.item?.coupons && this.item?.coupons?.length > 0;
    },
    showAddToCartButton() {
      if (this.isItemListOnly(this.item)) {
        return true;
      }
      return (
        (!this.isInStock(this.item) ||
          this.showQuantityAdjuster(this.item) ||
          this.isInStoreOnly(this.item) ||
          this.getMinimumQuantity(this.item).comparedTo(0) <= 0) &&
        this.$configuration.cartEnabled
      );
    },
    showIncrementButton() {
      if (this.isItemListOnly(this.item)) {
        return false;
      }
      return (
        !this.isInStoreOnly(this.item) &&
        this.isInStock(this.item) &&
        !this.getMinimumQuantity(this.item).comparedTo(0) <= 0 &&
        !this.isCustomerModeScan &&
        this.$configuration.cartEnabled
      );
    },
    eligibleOrderTypes() {
      return this.eligibleOrderTypesForItem(this.item);
    },
  },
  data() {
    return {
      selected: '',
      placeholder: '',
      liked: false,
      quantityEdit: false,
    };
  },
  methods: {
    ...mapActions({ setInStore: 'cart/setInStore', reload: 'cart/reload', sync: 'cart/sync' }),
    isItemInCart(item) {
      return !this.getQuantityInCart(item).isZero();
    },
    showQuantityAdjuster(item) {
      return this.quantityEdit && !this.getQuantityInCart(item).isZero();
    },
    async openCouponsModal() {
      try {
        await ModalService.open(WCCouponsModal, { item: this.item });
      } catch (error) {
        if (error !== 'Dismissed')
          ToastService.open(WCSimpleToastVue, {
            props: {
              variant: 'danger',
              title: this.$t('error'),
              message: this.$t('showCouponMoreInfoFailure'),
            },
            timeout: 7000,
          });
      }
    },
    addToCart() {
      this.$emit('addToCart', this.item);
    },
    async quantityClickedIncrement(item) {
      await this.setInStore(this.isScanAndGoMode);

      if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
        this.showOrderTypeToast();
      } else if (this.isScanAndGoMode || (!this.isInStore && !this.isScanAndGoMode)) {
        this.quantityEdit = !this.quantityEdit;
        await this.increment(item);
      }

      if (this.isInStore && !this.isScanAndGoMode) {
        try {
          await ModalService.open(ExitScanAndGo);
        } catch (error) {
          this.dismiss('Canceled');
          console.error(error);
        }
      }
    },
    quantityClicked() {
      this.quantityEdit = !this.quantityEdit;
    },
    hide() {
      this.quantityEdit = false;
    },
    showOrderTypeToast() {
      ToastService.open(WCAddToOrderTypeCartToast, {
        props: {
          item: this.item,
          title: this.$t('verifyOrderType'),
          orderTypes: this.eligibleOrderTypes,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-card__wrapper {
  position: relative; /* Allows absolute elements to position relative to this. Ex: BasicAddToCartButton */
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15rem;
  background-color: #ffffff;

  &.wc-card__wrapper--fixed-size {
    width: 10.6rem;
  }
}

.wc-card {
  border: none;
  position: relative;
  width: inherit;
  height: inherit;
  line-height: normal;

  .wc-card__top {
    height: 65%;
    border-bottom: 1px solid var(--secondary, $secondary);
    position: relative;

    .wc-card__heart-icon {
      position: absolute;
      left: 0;
      top: 0;
      font-size: $font-size-lg;
      //Remove padding created by wrapping btn
      line-height: 0;
      border: none;
    }
    .wc-card__add-icon,
    .quantity-badge,
    .wc-card__cart-icon {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      color: var(--primary, $primary);
      cursor: pointer;
    }
    .quantity-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.7rem;
      border-radius: 10rem;
      height: 2rem;
      background-color: var(--primary, $primary);
      color: var(--white, $white);
    }
    .wc-card__cart-icon {
      top: 3rem;
    }
    .wc-card__camera-icon {
      font-size: $font-size-7xl;
    }
    .wc-card__img {
      object-fit: contain;
      max-width: calc(100% - 1rem);
      max-height: calc(100% - 1rem);
      width: auto;
      height: auto;
      padding-top: $pad-4;
      padding-bottom: $pad-3;
    }

    .wc-hide-price-sign-in {
      position: absolute;
      top: 0.5rem;
    }

    .wc-promo-ribbon-wrap {
      position: absolute;
      bottom: 0.25rem;
      right: 0.5rem;
    }
  }

  .wc-card__body {
    height: 25%;
    a {
      color: var(--dark, $dark);
    }
    .wc-card__price {
      max-width: 50%;
    }
  }

  .wc-card__footer {
    height: 10%;
    .wc-card__brand {
      a {
        color: var(--primary, $primary);
      }
    }
  }
}

.wc-coupon-card-mobile__coupon-icon {
  color: var(--primary, $primary);
  cursor: pointer;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quantity-overlay {
  place-items: center;
  position: absolute; /* Sit relative to the wc-card__wrapper */
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.end-0 {
  right: 0;
}

@include media-breakpoint-only(xs) {
  .wc-card__wrapper {
    justify-content: center;
    &.wc-card__wrapper--fixed-size {
      width: 96%;
    }
  }
}
</style>
