<template>
  <div>
    <WCWebAds v-if="$configuration.ads" :webAds="$configuration.ads" />
    <div class="m-2 pt-5">
      <h1 class="pb-5">{{ $configuration.orderTypesMessage }}</h1>
      <div class="container card-group">
        <div class="wc-order-types row" :class="{ 'wc-order-types--grid': orderTypes.length > 3 }">
          <WCOrderTypeCard
            v-for="orderType in orderTypes"
            :key="orderType.id"
            :orderType="orderType"
            class="d-flex wc-order-type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WCOrderTypeCard from '@/modules/orderTypes/components/WCOrderTypeCard/WCOrderTypeCard.vue';
import WCWebAds from '@/components/WCWebAds/WCWebAds.vue';

export default {
  name: 'WCOrderTypes',
  props: {
    orderTypes: {
      type: Array,
      required: true,
    },
  },
  components: { WCOrderTypeCard, WCWebAds },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-order-types {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding-bottom: $pad-4;
}

.wc-order-types--grid {
  display: grid;
  column-gap: 3rem;
  row-gap: 3rem;
}

@include media-breakpoint-up(xl) {
  .wc-order-types--grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media-breakpoint-only(lg) {
  .wc-order-types--grid {
    grid-template-columns: repeat(3, calc(33% - 0.25rem));
  }
}
@include media-breakpoint-only(md) {
  .wc-order-types--grid {
    grid-template-columns: repeat(3, calc(33% - 0.25rem));
  }
}
@include media-breakpoint-only(sm) {
  .wc-order-types--grid {
    grid-template-columns: repeat(2, calc(33% - 0.25rem));
  }
}

.wc-order-type {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
