var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile && _vm.webAd.link !== '')?_c('a',{staticClass:"wc-web-ad position-relative rounded-xl user-select-none shadow-sm",style:({
    'background-image': _vm.hasMessage
      ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${_vm.imageSrc}')`
      : `url('${_vm.imageSrc}')`,
  }),attrs:{"href":_vm.webAd.link,"target":_vm.isExternalLink(_vm.webAd.link) ? '_blank' : '',"rel":_vm.isExternalLink(_vm.webAd.link) ? 'noopener noreferrer' : '',"aria-label":_vm.webAd.label}},[(!_vm.hasImg)?_c('font-awesome-icon',{staticClass:"wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",attrs:{"icon":"camera","title":_vm.$t('noProductImageAvailable')}}):_vm._e(),(!_vm.hasImg)?_c('div',{staticClass:"text-primary wc-web-ad__label d-flex align-items-center text-wrap"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.webAd.label))]})],2):_vm._e()],1):(!_vm.isMobile && (!_vm.webAd.link || _vm.webAd.link === ''))?_c('button',{staticClass:"wc-web-ad btn position-relative rounded-xl user-select-none shadow-sm",style:({
    'background-image': _vm.hasMessage
      ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${_vm.imageSrc}')`
      : `url('${_vm.imageSrc}')`,
  }),attrs:{"aria-label":_vm.webAd.label}},[(!_vm.hasImg)?_c('font-awesome-icon',{staticClass:"wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",attrs:{"icon":"camera","title":_vm.$t('noProductImageAvailable')}}):_vm._e(),(!_vm.hasImg)?_c('div',{staticClass:"text-primary wc-web-ad__label d-flex align-items-center text-wrap"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.webAd.label))]})],2):_vm._e()],1):(_vm.isMobile && _vm.webAd.link !== '')?_c('a',{attrs:{"href":_vm.webAd.link,"target":_vm.isExternalLink(_vm.webAd.link) ? '_blank' : '',"rel":_vm.isExternalLink(_vm.webAd.link) ? 'noopener noreferrer' : '',"aria-label":_vm.webAd.label}},[_c('div',{staticClass:"wc-web-ad position-relative rounded-xl user-select-none shadow-sm wc-web-ad--fixed-size w-100",style:({
      'background-image': _vm.hasMessage
        ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${_vm.imageSrc}')`
        : `url('${_vm.imageSrc}')`,
    })}),(!_vm.hasImg)?_c('font-awesome-icon',{staticClass:"wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",attrs:{"icon":"camera","title":_vm.$t('noProductImageAvailable')}}):_vm._e(),(!_vm.hasImg)?_c('div',{staticClass:"text-primary wc-web-ad__label d-flex align-items-center text-wrap"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.webAd.label))]})],2):_vm._e()],1):(_vm.isMobile && (!_vm.webAd.link || _vm.webAd.link === ''))?_c('button',{staticClass:"wc-web-ad btn position-relative rounded-xl user-select-none shadow-sm wc-web-ad--fixed-size w-100",style:({
    'background-image': _vm.hasMessage
      ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${_vm.imageSrc}')`
      : `url('${_vm.imageSrc}')`,
  }),attrs:{"aria-label":_vm.webAd.label}},[(!_vm.hasImg)?_c('font-awesome-icon',{staticClass:"wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4",attrs:{"icon":"camera","title":_vm.$t('noProductImageAvailable')}}):_vm._e(),(!_vm.hasImg)?_c('div',{staticClass:"text-primary wc-web-ad__label d-flex align-items-center text-wrap"},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.webAd.label))]})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }