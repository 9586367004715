<template>
  <label :class="classes">
    <slot />
    <span v-if="_required" class="text-danger"> *</span>
  </label>
</template>

<script>
export default {
  name: 'WCLabel',
  inject: {
    field: { default: undefined },
  },
  props: {
    required: Boolean,
  },
  computed: {
    _required() {
      return this.field ? this.field.$params.required : this.required;
    },
    classes() {
      const classes = [];

      if (this.field && this.field.$error) {
        classes.push('text-danger');
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped></style>
