import { render, staticRenderFns } from "./WCCartDrawerItemPricing.vue?vue&type=template&id=06bdb2d7&scoped=true"
import script from "./WCCartDrawerItemPricing.vue?vue&type=script&lang=js"
export * from "./WCCartDrawerItemPricing.vue?vue&type=script&lang=js"
import style0 from "./WCCartDrawerItemPricing.vue?vue&type=style&index=0&id=06bdb2d7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bdb2d7",
  null
  
)

export default component.exports