<template>
  <a
    v-if="!isMobile && webAd.link !== ''"
    :href="webAd.link"
    :target="isExternalLink(webAd.link) ? '_blank' : ''"
    :rel="isExternalLink(webAd.link) ? 'noopener noreferrer' : ''"
    class="wc-web-ad position-relative rounded-xl user-select-none shadow-sm"
    :style="{
      'background-image': hasMessage
        ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${imageSrc}')`
        : `url('${imageSrc}')`,
    }"
    :aria-label="webAd.label"
  >
    <font-awesome-icon
      v-if="!hasImg"
      class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
      icon="camera"
      :title="$t('noProductImageAvailable')"
    />
    <div v-if="!hasImg" class="text-primary wc-web-ad__label d-flex align-items-center text-wrap">
      <slot>{{ webAd.label }}</slot>
    </div>
  </a>
  <button
    v-else-if="!isMobile && (!webAd.link || webAd.link === '')"
    class="wc-web-ad btn position-relative rounded-xl user-select-none shadow-sm"
    :style="{
      'background-image': hasMessage
        ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${imageSrc}')`
        : `url('${imageSrc}')`,
    }"
    :aria-label="webAd.label"
  >
    <font-awesome-icon
      v-if="!hasImg"
      class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
      icon="camera"
      :title="$t('noProductImageAvailable')"
    />
    <div v-if="!hasImg" class="text-primary wc-web-ad__label d-flex align-items-center text-wrap">
      <slot>{{ webAd.label }}</slot>
    </div>
  </button>

  <a
    v-else-if="isMobile && webAd.link !== ''"
    :href="webAd.link"
    :target="isExternalLink(webAd.link) ? '_blank' : ''"
    :rel="isExternalLink(webAd.link) ? 'noopener noreferrer' : ''"
    :aria-label="webAd.label"
  >
    <div
      class="wc-web-ad position-relative rounded-xl user-select-none shadow-sm wc-web-ad--fixed-size w-100"
      :style="{
        'background-image': hasMessage
          ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${imageSrc}')`
          : `url('${imageSrc}')`,
      }"
    ></div>
    <font-awesome-icon
      v-if="!hasImg"
      class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
      icon="camera"
      :title="$t('noProductImageAvailable')"
    />
    <div v-if="!hasImg" class="text-primary wc-web-ad__label d-flex align-items-center text-wrap">
      <slot>{{ webAd.label }}</slot>
    </div>
  </a>

  <button
    v-else-if="isMobile && (!webAd.link || webAd.link === '')"
    class="wc-web-ad btn position-relative rounded-xl user-select-none shadow-sm wc-web-ad--fixed-size w-100"
    :style="{
      'background-image': hasMessage
        ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${imageSrc}')`
        : `url('${imageSrc}')`,
    }"
    :aria-label="webAd.label"
  >
    <font-awesome-icon
      v-if="!hasImg"
      class="wc-web-ad__fallback-img position-absolute text-muted font-size-5xl ml-n4 mt-n4"
      icon="camera"
      :title="$t('noProductImageAvailable')"
    />
    <div v-if="!hasImg" class="text-primary wc-web-ad__label d-flex align-items-center text-wrap">
      <slot>{{ webAd.label }}</slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'WCWebAd',
  computed: {
    /* TODO: Should return true if the webAd contains an overlay message */
    hasMessage() {
      return false;
    },
    hasImg() {
      return !!this.webAd.image;
    },
    imageSrc() {
      return this.hasImg ? `${this.$configuration.cdn}/api/img/${this.webAd.image}` : '';
    },
  },
  props: {
    webAd: {
      type: Object,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // Does the link for the Web Ad take the user to an external site?
    isExternalLink(link) {
      // 2nd condition is needed so relative links do not open in new tabs
      return !link.includes(this.$configuration.siteUrl) && link.includes('.com');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-web-ad {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  width: 100%;

  &.wc-web-ad--fixed-size {
    height: 8.75rem;
    width: 17.25rem;
  }

  .wc-web-ad__label {
    height: 100%;
    width: 100%;
    padding: 10% 20% 10% 10%;
    color: var(--light, $color-contrast-light);
  }

  .wc-web-ad__fallback-img {
    top: 50%;
    left: 50%;
  }
}
</style>
