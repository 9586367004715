import { render, staticRenderFns } from "./WCBasicAddToCartButton.vue?vue&type=template&id=a137bb36&scoped=true"
import script from "./WCBasicAddToCartButton.vue?vue&type=script&lang=js"
export * from "./WCBasicAddToCartButton.vue?vue&type=script&lang=js"
import style0 from "./WCBasicAddToCartButton.vue?vue&type=style&index=0&id=a137bb36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a137bb36",
  null
  
)

export default component.exports